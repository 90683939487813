import { BaseModel } from '@core/base-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';

export interface CreateOrderDto {
  paymentMethod: MultiMetalPaymentType;
  paymentMethodId: string;
  quantity: number;
  fiatCurrency: string;
  smartCoin: string;
  paymentTransactionId: string;
  deviceDataReference: string;
}

export interface CreateOrderQuery {
  id: string;
  quantity: number;
  smartCoin: string;
  transactionFee: number;
  totalValue: number;
  fiatCurrency: string;
  isCard: boolean;
  paymentMethodId: string;
}

export class CreateOrderModel extends BaseModel<CreateOrderQuery> {
  private id: string;
  private quantity: number;
  private smartCoin: string;
  private transactionFee: number;
  private totalValue: number;
  private fiatCurrency: string;
  private isCard: boolean;
  private paymentMethodId: string;

  constructor(data: CreateOrderQuery) {
    super();
    this.update(data);
  }

  setId(id: string) {
    this.id = id;
  }

  setTransactionFee(transactionFee: number) {
    this.transactionFee = transactionFee;
  }

  get asJson(): CreateOrderQuery {
    return {
      id: this.id,
      quantity: this.quantity,
      smartCoin: this.smartCoin,
      transactionFee: this.transactionFee,
      totalValue: this.totalValue,
      fiatCurrency: this.fiatCurrency,
      isCard: this.isCard,
      paymentMethodId: this.paymentMethodId,
    };
  }
}

export interface OrderCreateQuery {
  id: string;
  amount: number;
  gdcBought: number;
  spotPrice: number;
}

export class OrderCreateModel extends BaseModel<OrderCreateQuery> {
  id: string;
  amount: number;
  gdcBought: number;
  spotPrice: number;

  constructor(data: OrderCreateQuery) {
    super();
    this.update(data);
  }

  get asJson(): OrderCreateQuery {
    return {
      id: this.id,
      amount: this.amount,
      gdcBought: this.gdcBought,
      spotPrice: this.spotPrice,
    };
  }
}
