import { BaseModel } from '@core/base-model';

export type DeviceDataInitializationResponseDto = {
  transactionId: string;
  jwt: string;
  url: string;
  bin: string;
};

export interface DeviceDataCollectionDto {
  status: boolean;
  sessionId: string;
}

export type DeviceDataInitializationResultDto = {
  transactionId: string;
  sessionId: string;
  status: boolean;
};

export class DeviceDataInitializationModel extends BaseModel<DeviceDataInitializationResultDto> {
  private transactionId: string;
  private sessionId: string;
  private status: boolean;


  constructor(data: DeviceDataInitializationResultDto) {
    super();
    this.update(data);
  }

  get asJson(): DeviceDataInitializationResultDto {
    return {
      transactionId: this.transactionId,
      sessionId: this.sessionId,
      status: this.status,
    };
  }
}
