import { BaseModel } from '@core/base-model';

export type CooldownDto = {
  blockedTill: string;
  blockedTillDate: Date | undefined;
};

export class CooldownModel extends BaseModel<CooldownDto> {
  private blockedTill: string;
  private blockedTillDate: Date | undefined;

  constructor(data: CooldownDto) {
    super();
    this.update(data);
    if (data.blockedTill) {
      this.blockedTillDate = new Date(data.blockedTill);
    }
  }

  get asJson(): CooldownDto {
    return {
      blockedTill: this.blockedTill,
      blockedTillDate: this.blockedTillDate,
    };
  }
}
